import initSplashScreen from './scripts/splash-screen';
import { initWebpListeners } from './scripts/webp-handler';
import { initTabHandler } from './scripts/keyboard-handler';
import { detect } from 'detect-browser';

initSplashScreen();
initWebpListeners();
initTabHandler();

// functions to call when the Vue app has loaded
window.addEventListener('vueapploaded', handleVueAppLoaded);

/**
 * The function that runs when the vue part of the page has loaded.
 * Try and only run here to not have multiple event listeners running.
 */
function handleVueAppLoaded() {       
    // find if browser is using Apple's WebKit engine
    const browser = detect();
    if (browser && browser.os.toLowerCase() === 'ios' || browser.os.toLowerCase() === 'mac os') {
        document.getElementsByTagName('body')[0].classList.add('isWebKit');
    }
}