/**
 * Remove the "loading portal" splash screen
 */
export default function initSplashScreen() {
    // javascript is up and running - remove the loading screen, if active
    window.__MAIN_LOAD__ = true;

    // check that the timeout was triggered
    if (window.__LOAD_START__) {
        let splash = document.querySelector('.app--splash-screen');
        splash && splash.classList.add('app--splash-screen__hidden');
    }
}